<template>
  <button
    :type="props.nativeType"
    class="c-btn"
    :class="[
      `c-btn--${props.type}`,
      `c-btn--${props.size}`,
      { 'is-loading': props.loading },
      { 'c-btn--icon': props.iconOnly },
    ]"
    :disabled="props.disabled"
  >
    <div v-if="props.loading" class="c-btn__loader">
      <se-spinner />
    </div>
    <slot />
  </button>
</template>

<script setup lang="ts">
import { ButtonType } from './types/ButtonType';
import { ButtonSize } from './types/ButtonSize';
import { ButtonNativeType } from './types/ButtonNativeType';
import SeSpinner from '../spinner/Spinner.vue';

const props = withDefaults(defineProps<{
  type?: ButtonType,
  size?: ButtonSize,
  iconOnly?: boolean;
  nativeType?: ButtonNativeType;
  loading?: boolean;
  disabled?: boolean;
}>(), {
  type: 'primary',
  size: 'medium',
  iconOnly: false,
  nativeType: 'button',
  loading: false,
  disabled: false,
});
</script>

<script lang="ts">
export default {
  name: 'SeButton',
};
</script>
